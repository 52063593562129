import React from 'react'
import logo from './Teffety-logo.png';

const Process = () => {
  return (
    <div>
      <div className='process'>
        <div className='logo'>
            <img src={logo} alt='logo'/>
        </div>
        <div className='Content'>
           <h1>We're currently developing our new website.</h1>
        </div>
        <div className='pharagraph'>
            <p>Our mission is to deliver high-quality services that bring value to our customers' lives. We strive to exceed expectations through innovation and dedication.!</p>
        </div>
      </div>
    </div>
  )
}

export default Process
