import React from 'react'
import facebook from './facebook.png';
import instagram from './instagram.png';
import linkedin from './linkedin.png';
import email_icon from './email.png'
import phone_icon from './call.png'
 
const Footer = () => {
  return (
    <div>
    <div className='footer'>
    <div className='social'>
      <div className='links'>
        <a href=' https://www.facebook.com/letstalk.teffety/'><img src={facebook} alt='social' /></a>
      </div>
      <div className='lines'>|</div>
      <div className='links'>
        <a href=' https://www.instagram.com/letstalk.teffety/'><img src={instagram} alt='social' /></a>
      </div>
      <div className='lines'>|</div>
      <div className='links'>
        <a href='https://www.linkedin.com/in/keerthana-chandrasekaran/'><img src={linkedin} alt='social' /></a>
      </div>
    </div>
    <div className='lines'>|</div>
    <div className='mobile'>
      <img src={phone_icon} alt='phone'/>
      <p>+91 91501 54708</p>
    </div>
    <div className='lines'>|</div>
    <div className='email'>
    <img src={email_icon} alt='email'/>
    <p>letstalk.teffety@gmail.com</p>
    </div>
    </div>
    </div>
  )
}

export default Footer