import React from 'react'
import './App.css';
import Process from './Process';
import Footer from './Footer';
import Contect from './Info-contect';

const App = () => {
  return (
    <div id='main_root'>
    <div className='index-container'>
      <Process/>
      <Footer/>
    </div>
    </div>
  )
}

export default App
