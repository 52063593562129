import React from 'react'

function contect() {
  return (
    <div className='contact-com'>
      <h2>contact :+91 91501 54708 </h2>
      <h2>Email : letstalk.teffety@gmail.com </h2>
    </div>
  )
}

export default contect